@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

.small-caps {
  font-variant: small-caps;
}

.sponsor-bar {
  img {
    @apply w-[20vw] md:w-auto md:max-h-[60px]
  }
}

.news-content {
  a {
    @apply text-bja-secondary
  }
  a:hover {
    @apply text-bja-primary
  }
  p {
    @apply text-justify text-sm md:text-base xl:text-xl
  }
  img {
    @apply mx-auto
  }
  h1 {
    @apply text-xl md:text-2xl xl:text-3xl small-caps font-semibold text-bja-secondary
  }
  h2 {
    @apply text-lg md:text-xl xl:text-2xl small-caps font-semibold
  }
  h3 {
    @apply text-base md:text-lg xl:text-xl small-caps font-semibold
  }
  h4 {
    @apply text-sm md:text-base xl:text-lg small-caps
  }
  @apply text-bja-primary
}

.scoreboard ,td,th {
  @apply md:px-[20px] md:py-[20px] px-2 py-1
}

body {
  margin: 0;
  font-family: 'Raleway', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
